<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast/>
                <DataTable
                    ref="dt"
                    :value="products"
                    dataKey="id"
                    :paginator="true"
                    :rows="50"
                    :filters="filters"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25,50]"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                    sortField="metric"
                    :sortOrder=-1
                    :loading="loading1"
                    responsiveLayout="scroll"
                >
                    <template #empty>
                        Nothing to do.
                    </template>

                    <Column :header="User">
                        <template #body="slotProps">
                            <div class="flex" style="align-items: center">
                            <div class="p-avatar-custom" :style="{backgroundImage: 'url(images/' + slotProps.data.id + '.jpg), url(images/ludi_placeholder.png)'}">
                            </div>
                            <span style="width: 50%; display: inline-block; margin-left: .5em; vertical-align: middle" class="image-text">
                                <router-link v-if="!slotProps.data.coach" :to="{ name: 'userprofile', params: {uid: slotProps.data.id }}" target="_blank">
                                    {{slotProps.data.name}}
                                </router-link>
                                <router-link v-else :to="{ name: 'coachprofile', params: {uid: slotProps.data.id }}" target="_blank">
                                    {{slotProps.data.name}}
                                </router-link>
                            </span>
                            </div>
                        </template>
                    </Column>
                    <Column field="xlm_public_key" header="XLM public key" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.xlm_public_key}}
                        </template>
                    </Column>
                    <Column field="reason" header="Reason" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.reason}}
                        </template>
                    </Column>
                    <Column field="amount" header="amount" :sortable="false" >
                        <template #body="slotProps">
                            {{slotProps.data.amount}}
                        </template>
                    </Column>

                    <Column headerStyle="min-width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible">
                        <template #body="slotProps">
                            <Button v-if="slotProps.data.xlm_public_key != '' && slotProps.data.xlm_public_key != ' ' && slotProps.data.xlm_public_key != undefined" type="button" icon="pi pi-check" class="p-button-rounded p-button-secondary" v-on:click="accept(slotProps.data.id)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import UserService from '../service/UserService';

export default {
    data() {
        return {
            products: null,
            product: {},
            filters: {},
            windowWidth: window.innerWidth,
            loading1: true,
        }
    },
    userService: null,
    created() {
        this.userService = new UserService();
        window.addEventListener("resize", () => {
            this.windowWidth = window.innerWidth;
        });
    },
    mounted() {
        this.reload();
    },
    methods: {
        reload() {
            this.userService.getXLMAdmin().then(data => {
                this.loading1 = false;
                this.products = data;
            });
        },
        accept(id_) {
            console.log(id_);
            this.userService.acceptXLMAdmin(id_).then(() => {
                this.reload();
            });
        }
    }
}
</script>

<style lang="scss">
@media (max-width: 450px) {
    .layout-wrapper .layout-main-container {
        padding-left: 0rem!important;
    }
    .layout-main-container {
        padding: 7rem 0rem 2rem 0rem!important;
    }
}
</style>
<style scoped lang="scss">
@media (max-width: 450px) {
    .grid {
        margin: 0px!important;
    }
    .card {
        padding: 0px!important;
    }
}
img {
    width: 32px;
    border-radius: 30px;
}
</style>
<style>
.p-avatar.p-avatar-image {
    overflow: hidden;
}
.p-avatar img {
    height: 40px!important;
    width: auto!important;
}
.p-avatar-custom {
    min-width: 2rem!important;
    overflow: hidden;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    /* border: 1px solid black; */

    background-size: cover;
    background-position: center;
}
.p-avatar-custom img {
    width: 100%;
}
.textarea {
    white-space: pre-wrap;
}
.p-rating {
    min-width: 110px;
}
</style>
